import FontFaceObserver from 'fontfaceobserver';
import { ICON_SPRITE_URL } from './constants';

/**
 * Load fonts via FontFaceObserver if supported
 */
try {
  if (sessionStorage.fontsLoaded) {
    document.documentElement.className += ' has-optimized-font has-fonts';
  } else {
    const optimizedSubset = new FontFaceObserver('Bw Modelica Optimized');

    optimizedSubset.load().then(() => {
      document.documentElement.className += ' has-optimized-font';

      const fonts = [
        new FontFaceObserver('Bw Modelica', { weight: 400 }),
        new FontFaceObserver('Bw Modelica', { weight: 500 }),
        new FontFaceObserver('Bw Modelica', { weight: 600 }),
      ];

      Promise.all(fonts.map(font => font.load())).then(() => {
        document.documentElement.className += ' has-fonts';
        sessionStorage.fontsLoaded = true;
      });
    });
  }
} catch (e) {
  // Font loading failed
  document.documentElement.className += ' has-optimized-font has-fonts';
}


/**
 * Inject icons
 */
const request = new XMLHttpRequest();
request.open('GET', ICON_SPRITE_URL, true);
request.onreadystatechange = () => {
  if (request.readyState === 4) {
    const div = document.createElement('div');
    const ready = () => document.body.appendChild(div);

    div.setAttribute('style', 'height: 0; width: 0; position: absolute; visibility: hidden; display: none');
    div.innerHTML = request.responseText;

    if (document.readyState === 'complete') {
      ready();
    } else {
      document.addEventListener('DOMContentLoaded', () => ready(), false);
      window.addEventListener('load', () => ready(), false);
    }
  }
};
request.send();
